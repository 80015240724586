import { render, staticRenderFns } from "./ExerciseStartupList.vue?vue&type=template&id=35fb8cba"
import script from "./ExerciseStartupList.vue?vue&type=script&lang=js"
export * from "./ExerciseStartupList.vue?vue&type=script&lang=js"
import style0 from "./ExerciseStartupList.vue?vue&type=style&index=0&id=35fb8cba&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports